@import "../mixins/media";
@import "../mixins/clearfix";

/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/

.ec-newsRole {
  padding: 40px 0 0;

  @include media_desktop {
    padding: 20px 0 0;
  }

  & &__news {

    box-sizing: border-box;

    @include media_desktop {
      border: 16px solid #F8F8F8;
      padding: 20px 30px;
    }
  }
  & &__newsItem {
    width: 100%;

    &:not(:last-of-type){
      border-bottom: 1px solid #ccc;
    }

    &:last-of-type {
      margin-bottom: 20px;

      @include media_desktop {
        margin-bottom: 0;
      }
    }


    @include media_desktop {

      padding: 20px 0;
    }
  }
  & &__newsHeading {
    cursor: pointer;

    @include media_desktop {
      display: flex;
    }

  }
  & &__newsDate {
    display: block;
    margin:  15px 0 5px;
    font-size: 12px;
    color: black;

    @include media_desktop {
      display: inline-block;
      margin: 0;
      min-width: 120px;
      font-size: 14px;
    }

  }
  & &__newsColumn {
    display: flex;

    @include media_desktop {
      display: inline-flex;
      min-width: calc(100% - 120px);
    }
  }

  & &__newsTitle {
    display: inline-block;
    margin-bottom: 10px;
    width: 90%;
    font-size: 14px;
    font-weight: bold;
    color: #7D7D7D;
    line-height: 1.6;

    @include media_desktop {
      margin-bottom: 0;
      line-height: 1.8;
    }

  }
  & &__newsClose {
    display: inline-block;
    width: 10%;
    position: relative;

  }
  & &__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: black;
    cursor: pointer;
    position: absolute;
    right: 5px;
  }
  & &__newsDescription {
    display: none;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;

    @include media_desktop {
      margin: 20px 0 0;
      line-height: 1.8;
    }

    a {
      color: #0092C4;
    }
  }
  &__newsItem.is_active &__newsDescription{
    margin: 0 0 10px;

    @include media_desktop {
      margin: 20px 0 0;
    }
  }
  &__newsItem.is_active &__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px);

  }

}
