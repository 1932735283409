@import "../mixins/media";
@import "../mixins/projects";
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole{
  @include container;
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;


  @include media_desktop {
    // margin-left: -16px;
    // margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    display: flex;
    flex-direction: column;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
      position: absolute;
      top: 0%;
      object-fit: contain;

      &:hover {
        transform: scale(1.1);
      }
    }
    

    .imgWrapper {
      outline: 1px solid $tmsBlack;
      width: 100%;
      overflow: hidden;
    }

    .rect {
      width:100%;
      position: relative;
      padding-top: 100%;
    }

    &:not(:first-child){
        a {
          color: black;
        }
    }

    &:nth-child(2n){
      // padding-right: 0;
      margin-right: 0;

      @include media_desktop {
        // padding-right: 0;
        margin-right: 2%;
      }
    }

    &:nth-child(4n){
      // padding-right: 0;
      margin-right: 0;

      @include media_desktop {
        // padding-right: 0;
        margin-right: 0;
      }
    }

    @include media_desktop(){
      margin-bottom: 4%;
      width: 48%;
      margin-bottom: 15px;
      // width: calc(100% / 4);
      width: 23.5%;

      &:not(:last-of-type){
        // padding-right: 40px;
        margin-right: 4%;
        @include media_desktop {
          // padding-right: 0;
          margin-right: 2%;
        }
      }
      
      &:nth-child(4n){
        // padding-right: 0;
        margin-right: 0;
  
        @include media_desktop {
          // padding-right: 0;
          margin-right: 0;
        }
      }
    }

    .ec-productRole__btn {
      margin-top: auto;
      margin-bottom: 15px;
    }
    
    .ec-productRole__actions {
        font-size: 14px;
        .ec-select select {
            margin-top: 5px;
            @include media_desktop(){
                font-size: 12px;
            }
        }
    }

    form {
        margin: 10px 0 0 0;
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  @include media_desktop {
    // margin-left: -16px;
    // margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      padding: 0 16px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em;
    }

  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}
