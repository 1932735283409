@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/

.ec-newItemRole {
  padding: 0;

  @include media_desktop {
    padding: 20px 0;
  }

  img {
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
  

  & &__list {
    display: flex;
    flex-wrap: wrap;

    @include media_desktop {
      flex-wrap: wrap;
    }

  }
  & &__listItem {
    box-sizing: border-box;
    margin-bottom: 4%;
    margin-right: 4%;
    width: 48%;
    height: auto;

        .imgWrapper {
            outline: 1px solid $tmsBlack;
            width: 100%;
            overflow: hidden;
        }

        .rect {
            width:100%;
        }

        &:not(:first-child){
            a {
                color: black;
        }

  }

    @include media_desktop {
      margin-bottom: 4%;
      width: 48%;
      margin-bottom: 15px;
      // width: calc(100% / 4);
      width: 23.5%;

      &:not(:last-of-type){
        // padding-right: 40px;
        margin-right: 4%;
        @include media_desktop {
          // padding-right: 0;
          margin-right: 2%;
        }
      }
      
      &:nth-child(4n){
        // padding-right: 0;
        margin-right: 0;
  
        @include media_desktop {
          // padding-right: 0;
          margin-right: 0;
        }
      }
    }

    &:nth-child(2n){
      // padding-right: 0;
      margin-right: 0;

      @include media_desktop {
        // padding-right: 0;
        margin-right: 2%;
      }
    }

    &:nth-child(4n){
      // padding-right: 0;
      margin-right: 0;

      @include media_desktop {
        // padding-right: 0;
        margin-right: 0;
      }
    }
  }
  & &__listItemHeading {
    margin-top: calc(45% - 20px);
  }
  & &__listItemTitle {
    margin: 8px 0;
    font-size: 14px;
    font-weight: bold;

    @include media_desktop {
      margin: 20px 0 10px;
      font-size: 16px;
    }
  }

  & &__listItemPrice {
    font-size: 12px;
  }

}
