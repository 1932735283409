@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/

.ec-categoryRole {
  padding: 40px 0;
  color: black;
  // background: #F8F8F8;

  @include media_desktop {
    padding: 20px 0;
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;

    @include media_desktop {
      flex-wrap: wrap;
    }

  }
  & &__listItem {
    margin-bottom: 20px;
    width: 100%;
    height: auto;

    .imgWrapper {
        overflow: hidden;
        outline: 1px solid $tmsBlack;

        img {
            transition: 0.3s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }
    }


    @include media_desktop {
      // width: calc(100% / 3);
      width: 31.33333%;

      &:not(:last-of-type){
        // padding-right: 30px;
        margin-right: 3%;
      }
    }

    &:nth-child(3n){
        margin-right: 0;

        @include media_desktop {
          margin-right: 0;
      }
    }

  }

}
