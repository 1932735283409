@import "../mixins/media";

/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role{
  @include container;
  width: auto;
  margin: 0 10px 0 10px;

  h3 {
    border-bottom: 2px solid #157FFB;
    font-weight: bold;
    padding: 5px 0 5px 0;
    clear: both;
  }
  @include media_desktop {
    margin: 0 70px 0 70px;
  }

}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole{
  @include mypageContainer;

  .ec-pageHeader h1{
    @include media_desktop {
      margin: 10px 0 48px;
      padding: 8px 0 18px;
    }
  }

}
