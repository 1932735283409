
// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$font-size: 16px;

$clrRed: #DE5D50;
$clrRich: #9a947e;
$clrGray: #F3F3F3;
$clrRightGray: #B8BEC4;
$clrExtraGray: #636378;
$clrDarkGray:#525263;

// tms custom color
$tmsBlue: #157FFB;
$tmsLightBlue: #25AFE8;
$tmsWhite: #FDFDFB;
$tmsOrange: #EE7700;
$tmsYellow: #FFDC00;
$tmsGray: #4C524C;
$tmsLightGray: #a7a7a7;
$tmsTextBlack: #191919;
$tmsBlack: #080808;
$tmsDeepBlue: #261F87;
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
